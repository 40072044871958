exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-videobook-js": () => import("./../../../src/pages/videobook.js" /* webpackChunkName: "component---src-pages-videobook-js" */),
  "component---src-templates-cine-js": () => import("./../../../src/templates/cine.js" /* webpackChunkName: "component---src-templates-cine-js" */),
  "component---src-templates-contacto-js": () => import("./../../../src/templates/contacto.js" /* webpackChunkName: "component---src-templates-contacto-js" */),
  "component---src-templates-critica-js": () => import("./../../../src/templates/critica.js" /* webpackChunkName: "component---src-templates-critica-js" */),
  "component---src-templates-galeria-js": () => import("./../../../src/templates/galeria.js" /* webpackChunkName: "component---src-templates-galeria-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-locucion-js": () => import("./../../../src/templates/locucion.js" /* webpackChunkName: "component---src-templates-locucion-js" */),
  "component---src-templates-privacidad-js": () => import("./../../../src/templates/privacidad.js" /* webpackChunkName: "component---src-templates-privacidad-js" */),
  "component---src-templates-teatro-js": () => import("./../../../src/templates/teatro.js" /* webpackChunkName: "component---src-templates-teatro-js" */),
  "component---src-templates-tv-js": () => import("./../../../src/templates/tv.js" /* webpackChunkName: "component---src-templates-tv-js" */)
}

